<template>
  <div class="map" id="map">
  </div>
</template>

<script>
import BMap from 'BMap'

export default {
  name: "SafeMap",
  props: {
    center: {lng: 0, lat: 0},
    zoom: Number,
    radius: Number,
  },
  watch: {
    radius: {
      handler(data) {
        this.sizeArea(data)
      },
      deep: true
    },
  },
  mounted() {
    this.createMap()
  },
  data() {
    return {
      map: null,
      area: null,
    }
  },
  methods: {
    createMap() {
      /* eslint-disable */
      console.log(this.center)
      // 创建Map实例
      const map = new BMap.Map("map");
      // 初始化地图,设置中心点坐标和地图级别
      const point = new BMap.Point(this.center.lng, this.center.lat)
      map.centerAndZoom(point, this.zoom)

      const marker = new BMap.Marker(point);        // 创建标注
      map.addOverlay(marker);

      // 创建半径
      this.area = new BMap.Circle(point, this.radius, {
        strokeColor: "blue",
        strokeWeight: 1,
        fillColor: "#E2E8F1",
        fillOpacity: 0.6
      })
      map.addOverlay(this.area)

      //添加地图类型控件
      // map.addControl(new BMap.MapTypeControl({
      //   mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP]
      // }))

      // 设置地图显示的城市 此项是必须设置的
      map.setCurrentCity("北京")

      //开启鼠标滚轮缩放
      map.enableScrollWheelZoom(true)

      // 拖拽监听
      map.addEventListener("dragend", () => {
        this.moveArea()
      })

      this.map = map
      /* eslint-enable */
    },
    sizeArea(radius) {
      this.map.removeOverlay(this.area)
      this.area.setRadius(radius)
      this.map.addOverlay(this.area)
    },
    moveArea() {
      const point = new BMap.Point(this.map.getCenter().lng, this.map.getCenter().lat)
      this.map.removeOverlay(this.area)
      this.area.setCenter(point)
      this.$emit("areaCenter",point);
      this.map.addOverlay(this.area)
    }
  },
}
</script>

<style scoped>
.map {
  height: 100%;
}
</style>